<template>
  <div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th></th>
              <th>중량기명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sensorList" :key="index">
              <td>
                <div class="input_radio">
                  <div>
                    <label
                      for="`radio1_${index}`"
                      class="label"
                      :class="{ active: checkSensor == index }"
                      @click="selectSensor(index)"
                    ></label>
                    <input
                      type="radio"
                      id="`radio1_${index}`"
                      name="search_opt"
                      :checked="checkSensor == index"
                    />
                  </div>
                </div>
              </td>
              <td>{{ item.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    sensorList: { type: Array, require: true },
    stepIndex: { require: true },
  },
  data() {
    return {
      checkSensor: null,
    };
  },
  methods: {
    selectSensor(index) {
      this.checkSensor = index;
      this.$emit('selectSensorInfo', this.sensorList[index]);
    },
  },
  watch: {
    stepIndex(newValue) {
      if (newValue != 0) {
        this.checkSensor = null;
      }
    },
  },
};
</script>

<style scoped></style>
