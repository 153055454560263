<template>
  <div class="modalPopup check_modal">
    <div class="modal_header">
      <h3 class="title">중량계 선택</h3>
      <button class="modal_close" @click="modalClose"></button>
    </div>
    <select-weight-sensor
      v-show="stepIndex == 0"
      :sensorList="sensorList"
      :stepIndex="stepIndex"
      @selectSensorInfo="selectSensorInfo = $event"
    ></select-weight-sensor>
    <record-weight-sensor
      v-show="stepIndex != 0"
      :recordList="recordList"
      :selectSensorInfo="selectSensorInfo"
    ></record-weight-sensor>
    <div class="modal_footer">
      <button
        v-show="stepIndex > 2"
        class="btn_sub2"
        @click="stepIndex == 3 ? getPlcData() : stopPlcData()"
      >
        {{ stepIndex == 3 ? '측정시작' : '중지' }}
      </button>
      <button
        class="btn_sub2"
        :disabled="stepIndex == 0 && selectSensorInfo == null"
        @click="
          stepIndex == 0
            ? (stepIndex = 1)
            : stepIndex == 1
            ? getPlcData()
            : stepIndex == 2
            ? stopPlcData()
            : modalClose()
        "
      >
        {{
          stepIndex == 0
            ? '선택'
            : stepIndex == 1
            ? '측정시작'
            : stepIndex == 2
            ? '중지'
            : '측정종료'
        }}
      </button>
    </div>
  </div>
</template>

<script>
import SPINNER_MIXIN from '@/mixins/spinner';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import SelectWeightSensor from '@/layouts/components/20/SelectWeigth';
import RecordWeightSensor from '@/layouts/components/20/recordWeigth';

export default {
  mixins: [SPINNER_MIXIN, FETCH_MIXIN, DRAG_MODAL_MIXIN],
  components: {
    SelectWeightSensor,
    RecordWeightSensor,
  },
  props: {
    sensorList: { type: Array, require: true },
    lot_id: {
      required: true,
    },
    product_id: {
      required: true,
    },
  },
  data() {
    return {
      checkMachine: [],
      stepIndex: 0,
      selectSensorInfo: null,
      checkSensor: null,

      sensor_list: null,
      interval: null,
      recordList: [],
    };
  },
  methods: {
    modalClose() {
      if (this.stepIndex > 1) {
        this.UPDATE_COUNTER_LOG();
        this.recordList = [];
      }
      this.stepIndex = 0;
      this.$emit('onclose');
    },
    async FETCH_SENSOR_LOG_LIST_NOW() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(sensors => {
          this.sensor_list = sensors;
          console.log('this.sensor_list', this.sensor_list);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async INSERT_COUNTER_LOG() {
      const insertCounterList = this.lodash
        .clonedeep(this.sensor_list)
        .filter(x => x.plc_sensor_id == this.selectSensorInfo.id)
        .map(x => {
          return {
            lot_id: this.lot_id,
            product_id: this.product_id,
            value: x.value,
            plc_sensor_detail_type_id: x.plc_sensor_detail_type_id,
            plc_sensor_id: x.plc_sensor_id,
          };
        });
      console.log('insertCounterList', insertCounterList);
      console.log('insertCounterList[0].value', insertCounterList[0].value);
      if (insertCounterList[0].value != 0) {
        this.recordList.push(insertCounterList[0]);
        console.log('this.recordList', this.recordList);

        this.INSERT_WITH_PAYLOAD(
          'INSERT_COUNTER_LOG',
          { data_list: insertCounterList },
          '계수기 점유',
        );
      }
    },
    async UPDATE_COUNTER_LOG() {
      await this.$store
        .dispatch('FETCH_COUNTER_LOG_NOW')
        .then(data_list => {
          if (data_list.length < 1) {
            // this.openOneButtonModal('오류', '연동 종료 중 오류발생');
          } else {
            let temp = this.lodash
              .clonedeep(data_list)
              .filter(x => x.plc_sensor_id == this.selectSensorInfo.id);
            temp = temp.map(x => {
              return {
                id: x.id,
                value: x.value,
              };
            });
            this.UPDATE_WITH_PAYLOAD('UPDATE_COUNTER_LOG', { data_list: temp });
          }
        })
        .catch(error => {
          console.log('error', error);
          this.openOneButtonModal('오류', '연동 종료 중 오류발생');
        });
    },
    async getPlcData() {
      this.stepIndex = 2;
      await this.FETCH_SENSOR_LOG_LIST_NOW();
      await this.INSERT_COUNTER_LOG();
      this.interval = setInterval(async () => {
        await this.FETCH_SENSOR_LOG_LIST_NOW();
        await this.INSERT_COUNTER_LOG();
      }, 9500);
    },
    stopPlcData() {
      this.stepIndex = 3;
      clearInterval(this.interval);
    },
  },
  async destroyed() {
    clearInterval(this.interval);
    await this.UPDATE_COUNTER_LOG();
  },
  created() {
    if (this.stepIndex != 0) {
      this.stepIndex = 0;
    }
  },
};
</script>

<style scoped></style>
